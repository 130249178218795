import React, { useState, useEffect, createContext } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import Loader from '../components/loader/loader';

import { link } from '../dynamic/api';

import Menu from '../menu/menu';

export const DataContext = createContext();

function GetData(props) {

  const { setAccess, userName, token, expireyDate } = props;

  const api = axios.create({
    baseURL: link,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const [loaderEffect, setLoaderEffect] = useState(true);




  const [roleData, setRoleData] = useState([]);

  const [zoneData, setZoneData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [subRegionData, setSubRegionData] = useState([]);

  const [branchData, setBranchData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [driverData, setDriverData] = useState([]);

  const [userData, setUserData] = useState(null);
  const [settingData, setSettingData] = useState(null);


  useEffect(() => {
    refUserData();
    refSettingData();
  }, []);


  // Driver Data
  const refDriverData = async () => {
    try {
      let data = { filter: { isActive: true, roleId: 2 } };
      const response = await api.get(`/user/getAll`, { params: data });
      if (response) {
        setDriverData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

  // clients  Data
  const refClientData = async (isCash) => {
    try {
      let data = { filter: { isActive: true, roleId: 3, isCash: isCash } };
      const response = await api.get(`/user/getAll`, { params: data });
      if (response) {
        setClientData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Zone Data
  const refZoneData = async () => {
    try {
      let data = { filter: { isActive: true } };
      const response = await api.get(`/zone/getAll`, { params: data });
      if (response) {
        setZoneData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

    // Sub-Region Data
    const refSubRegionData = async () => {
      try {
        let data = { filter: { isActive: true } };
        const response = await api.get(`/subRegion/getAll`, { params: data });
        if (response) {
          setSubRegionData(response.data.data);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };


  // Customer Data
  const refCustomerData = async (clientId) => {
    try {
      let data = { filter: { isActive: true } };
      if (clientId) { data.filter.clientId = clientId; }
      const response = await api.get(`/customer/getAll`, { params: data });
      if (response) {
        setCustomerData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Region Data
  const refRegionData = async () => {
    try {
      let data = { filter: { isActive: true } };
      const response = await api.get(`/region/getAll`, { params: data });
      if (response) {
        setRegionData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

  // Branch Data
  const refBranchData = async () => {
    try {
      let data = { filter: { isActive: true } };
      const response = await api.get(`/branch/getAll`, { params: data });
      if (response) {
        setBranchData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Role Data
  const refRoleData = async () => {
    try {
      let data = { filter: { isActive: true } };
      const response = await api.get(`/role/getAll`, { params: data });
      if (response) {
        setRoleData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // User Data
  const refUserData = async () => {
    try {
      let data = { userName: userName };
      const response = await api.get(`/user/getUser`, { params: data });
      if (response) {
        setUserData(response.data.data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Settings
  const refSettingData = async () => {
    try {
      const response = await api.get(`/Setting/getAll`);
      if (response) {
        setSettingData(response.data.data[0]);
      }
    } catch (error) {   
      toast.error('' + error);
    }
  };







  return (
    <div>

      <DataContext.Provider
        value={{
          setLoaderEffect,
          expireyDate,
          api,

          userData,
          refUserData,

          settingData,
          refSettingData,

          roleData,
          refRoleData,

          setAccess,

          refZoneData,
          zoneData,

          refBranchData,
          branchData,

          refRegionData,
          regionData,

          refSubRegionData,
          subRegionData,

          refCustomerData,
          customerData,

          refClientData,
          clientData,

          refDriverData,
          driverData,
        }}>


        {loaderEffect &&
          <Loader />
        }


        {userData && settingData &&
          <Menu setAccess={setAccess} settingData={settingData} />
        }



      </DataContext.Provider>




    </div>

  );
}


export default GetData;