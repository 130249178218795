import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './menu.css'
import toast from 'react-hot-toast';

import Loader from '../components/loader/loader';


import {
  BiMenu, BiGridAlt, BiSolidUserRectangle, BiBox, BiSolidTruck, BiArchiveIn, BiRename,
  BiCog, BiLogOut, BiMoneyWithdraw, BiBriefcase
} from "react-icons/bi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { GoArrowSwitch } from "react-icons/go";
import { IoEarth } from "react-icons/io5";
import { MdOutlineAccountBalance } from "react-icons/md";

import { DataContext } from '../init/getData';

const DashboardLazy = React.lazy(() => import('../dashboard/dashboard'));

const CustomerTabLazy = React.lazy(() => import('../customerTab/customerTab'));
const OrderTabLazy = React.lazy(() => import('../orderTab/orderTab'));
const PaymentTabLazy = React.lazy(() => import('../paymentTab/paymentTab'));


const DataEntryTabLazy = React.lazy(() => import('../dataEntryTab/dataEntryTabTab'));
const OrderToDriverTabLazy = React.lazy(() => import('../assigmentTab/assignmentTab'));
const AccountingTabLazy = React.lazy(() => import('../settlementTab/settlementTab'));
const BillingTabLazy = React.lazy(() => import('../clientPaymentTab/clientPaymentTab'));

const TransactionTabLazy = React.lazy(() => import('../transactionTab/transactionTab'));

const RegionTabLazy = React.lazy(() => import('../regionTab/regionTab'));

const SettingTabLazy = React.lazy(() => import('../settings/settingTab'));
const AllOrderTabLazy = React.lazy(() => import('../allOrderTab/allOrderTab'));



function Menu(props) {


  const { settingData, userData, setAccess, expireyDate, setLoaderEffect } = useContext(DataContext);


  const userId = userData?.id;
  const userName = userData?.name;
  const userRole = userData?.role;
  const userBranch = userData?.branch?.name;

  const [toggle, setToggle] = useState('sidebar');
  const [tog, setTog] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoaderEffect(false)
  }, []);



  const toggles = () => {
    if (tog == 1) {
      setTog(0);
      setToggle('sidebar open')
    }
    else {
      setTog(1);
      setToggle('sidebar')
    }
  }

  const signOut = () => {
    toast.success('Logged out');
    navigate('/');
    setAccess(false);
  }

  return (
    <div>
      <div className={toggle}>
        <div className="logo-details">
          <i className='bx'></i>
          <div className="logo_name">Top Fast</div>
          <i className='bx' id="btn" onClick={toggles}><BiMenu /></i>
        </div>
        <ul className="nav-list">

          {userRole.dashboardTab &&
            <li>
              <NavLink to="dashboard" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiGridAlt /></i>
                <span className="links_name">Dashboard</span>
              </NavLink>
              <span className="tooltip">Dashboard</span>
            </li>
          }

          {userRole.customerTab &&
            <li>
              <NavLink to="customerTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiSolidUserRectangle /></i>
                <span className="links_name">Customers</span>
              </NavLink>
              <span className="tooltip">Customers</span>
            </li>
          }

          {userRole.orderTab &&
            <li>
              <NavLink to="orderTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiBox /></i>
                <span className="links_name">Orders</span>
              </NavLink>
              <span className="tooltip">Orders</span>
            </li>
          }

          {userRole.paymentTab &&
            <li>
              <NavLink to="paymentTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><LiaFileInvoiceDollarSolid /></i>
                <span className="links_name">Payments</span>
              </NavLink>
              <span className="tooltip">Payments</span>
            </li>
          }


          {userRole.dataEntryTab &&
            <li>
              <NavLink to="dataEntryTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><GoArrowSwitch /></i>
                <span className="links_name">Data Entry</span>
              </NavLink>
              <span className="tooltip">Data Entry</span>
            </li>
          }

          {userRole.driverAssignmentTab &&
            <li>
              <NavLink to="orderToDriverTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiSolidTruck /></i>
                <span className="links_name">Driver Assignments</span>
              </NavLink>
              <span className="tooltip">Driver Assignments</span>
            </li>
          }


          {userRole.driverSettlementTab &&
            <li>
              <NavLink to="accountingTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><MdOutlineAccountBalance /></i>
                <span className="links_name">Driver Settlement</span>
              </NavLink>
              <span className="tooltip">Driver Settlement</span>
            </li>
          }

          {userRole.clientPaymentTab &&
            <li>
              <NavLink to="billingTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><LiaFileInvoiceDollarSolid /></i>
                <span className="links_name">Client Payments</span>
              </NavLink>
              <span className="tooltip">Client Payments</span>
            </li>
          }

          {userRole.transactionTab &&
            <li>
              <NavLink to="transactionTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiMoneyWithdraw /></i>
                <span className="links_name">Transactions</span>
              </NavLink>
              <span className="tooltip">Transactions</span>
            </li>
          }

          {userRole.regionTab &&
            <li>
              <NavLink to="regionTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><IoEarth /></i>
                <span className="links_name">Regions</span>
              </NavLink>
              <span className="tooltip">Regions</span>
            </li>
          }

          {userRole.allOrderTab &&
            <li>
              <NavLink to="allOrderTab" className={({ isActive }) => (isActive ? "active-style" : 'none')} >
                <i className='bx'><BiBox /></i>
                <span className="links_name">All Orders</span>
              </NavLink>
              <span className="tooltip">All Orders</span>
            </li>
          }

          {userRole.settingTab &&
            <li>
              <NavLink to="settingTab" className={({ isActive }) => (isActive ? "active-style" : 'none')}>
                <i className='bx' ><BiCog /></i>
                <span className="links_name">Settings</span>
              </NavLink>
              <span className="tooltip">Settings</span>
            </li>
          }




          <li className="profile">
            <div className="profile-details">
              <div className="name_job">
                <div className="name">User: {userName}</div>
                <div className="job">Role: {userBranch}Branch / {userRole.name}</div>

              </div>
            </div>
            <button onClick={signOut}>
              <i className='bx' id="log_out" ><BiLogOut /></i>
            </button>
          </li>
        </ul>
      </div>

      <section className="home-section">


        <Routes>
          <Route path='dashboard' element={<React.Suspense fallback={<Loader />}> <DashboardLazy /> </React.Suspense>} />

          <Route path='customerTab/*' element={<React.Suspense fallback={<Loader />}> <CustomerTabLazy /> </React.Suspense>} />
          <Route path='orderTab/*' element={<React.Suspense fallback={<Loader />}> <OrderTabLazy /> </React.Suspense>} />
          <Route path='paymentTab/*' element={<React.Suspense fallback={<Loader />}> <PaymentTabLazy /> </React.Suspense>} />

          <Route path='allOrderTab/*' element={<React.Suspense fallback={<Loader />}> <AllOrderTabLazy /> </React.Suspense>} />
          <Route path='regionTab/*' element={<React.Suspense fallback={<Loader />}> <RegionTabLazy /> </React.Suspense>} />
          <Route path='dataEntryTab/*' element={<React.Suspense fallback={<Loader />}> <DataEntryTabLazy /> </React.Suspense>} />
          <Route path='orderToDriverTab/*' element={<React.Suspense fallback={<Loader />}> <OrderToDriverTabLazy /> </React.Suspense>} />
          <Route path='accountingTab/*' element={<React.Suspense fallback={<Loader />}> <AccountingTabLazy /> </React.Suspense>} />
          <Route path='billingTab/*' element={<React.Suspense fallback={<Loader />}> <BillingTabLazy /> </React.Suspense>} />
          <Route path='transactionTab/*' element={<React.Suspense fallback={<Loader />}> <TransactionTabLazy /> </React.Suspense>} />
          <Route path='settingTab/*' element={<React.Suspense fallback={<Loader />}> <SettingTabLazy /> </React.Suspense>} />
        </Routes>

      </section>

    </div>

  );
}


export default Menu;