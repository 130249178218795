import React, { useState } from 'react';
import axios from 'axios';

import toast, {Toaster} from 'react-hot-toast';

import { FcBusinessman, FcKey } from "react-icons/fc";

import Logo_Png  from '../images/logo.jpg';
import GetData from '../init/getData';

function LogIn (props){

  const {loginApi, language} = props;

  const [access, setAccess] = useState(false);
  const [userName, setUserName] = useState('');
  const [token, setToken] = useState('');
  const [expireyDate, setExpireyDate] = useState('');

  const [loadingEffect, setLoadingEffect] = useState(false);




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);

 
    
    const form = document.getElementById("check_login_form");
      
      const formData = new FormData(form);
      
      //formData.append("type", this.props.cashType)
      const data = Object.fromEntries(formData);
      let res = await loginApi.post("/user/login", JSON.stringify(data))
      if(!res.data.status){  
        toast.error(res.data.message);
        setLoadingEffect(false);
      }
      else{
        toast.success('Login Successfull');
        loginApi.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
        setToken(res.data.token);
        setAccess(true);
        setLoadingEffect(false);
      }
  }

 



  if (access && token) {

    return <GetData setAccess={setAccess} userName={userName}  token={token} expireyDate={expireyDate}/>;
  }
  
      return (
        <div>
       
       <div className="d-flex justify-content-center align-items-center"  style={{height: '100vh', backgroundColor: '#1d8ebe'}}>

       <div className="row justify-content-center form-bg-image" style={{width: '30%'}}>
            <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500" >
            <div className="text-center text-md-center mb-4 mt-md-0">

            <img src={Logo_Png} height={350} alt="Description of the image" />

            </div>
            </div>
            </div>
            </div>


            
        <div className="row justify-content-center form-bg-image" style={{width: '40%'}}>

           

            <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500" >
            <div className="text-center text-md-center mb-4 mt-md-0">

       
            <h1 className="mb-0 h3">Sign in to our platform</h1>
     
            </div>
            <form method="POST" id="check_login_form" onSubmit={handleSubmit}>
            <div className="form-group mb-4">

  
      
            <label> User Name</label>
            <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
            <i className='bx' ><FcBusinessman /></i>
            </span>
            <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
             className="form-control"
             placeholder="example@company.com" id="userName"  name="userName" required></input>
            </div>  
       
        


            
            </div>


        
      
            <div className="form-group">
            <div className="form-group mb-4">
            <label>Your Password</label>
            <div className="input-group">
            <span className="input-group-text" id="basic-addon2">
            <i className='bx' ><FcKey /></i>
            </span>
            <input type="password" placeholder="Password" className="form-control" id="password" name="password" required></input>
            </div>  
            </div>
            </div>
       


  


            <div className="d-flex justify-content-between align-items-top mb-4">
            </div>
            <div className="d-grid" style={{ textAlign: 'center'}} >

         
            <button className="btn btn-primary" onClick={handleSubmit}>Sign in
            {loadingEffect && 
            <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px'}} role="status" aria-hidden="true"></span>
              }
            </button>
          


     


            </div>
            </form>
            </div>
            </div>
            </div>

    



           </div>

           <div style={{ textAlign: 'center'}} >
           
            </div>
            

           </div>
    );
  
}

export default LogIn;