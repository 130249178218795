import './App.css';
import './antTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.min.css';

import React, { useState, useEffect } from 'react';
import {Route, Routes, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

import LogIn from './login/login';
import { token } from "./dynamic/token";

import toast, {Toaster} from 'react-hot-toast';

import axios from 'axios';
import { link } from './dynamic/api';

const loginApi = axios.create({
  baseURL: link,
  headers: {
      'Content-Type': 'application/json'
  }
});

function App() {

  const [language, setLanguage] = useState(null);

      // Get Settings
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await loginApi.get(`/Setting/getAll`);
            if (response) {
              setLanguage(response.data.data[0].language);
            }} catch (error) {
            toast.error('' + error);}};
       fetchData();
      }, []);


  return (
    <div className="app" dir={language === 'arabic' ? 'rtl' : 'ltr'}>
      <Toaster position="top-center" reverseOrder={false}/>
      <Routes>
       <Route path="/*" element={<LogIn loginApi={loginApi} language={language} />} index />
      </Routes>
    </div>
  );
}

export default App;
